import React from 'react';
import './services.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import AOS from 'aos';

import H_Icon from '../../Image/Contact/Home_Icon.svg';
import Services_main from '../../Image/Services/Services.webp';
import Web from '../../Image/Services/Web.webp';
import App from '../../Image/Services/App.webp';
import DM from '../../Image/Services/DM.webp';
import Data from '../../Image/Services/Data.webp';
import Seo from '../../Image/Services/Seo.webp';
import UIUX from '../../Image/Services/UIUX.webp';

function Services() {

    AOS.init({ easing: 'ease' });

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>KeepiTech - Service</title>
                <link rel="canonical" href="https://keepitech.com/Services" />
            </Helmet>

            <section className='Contact_Main py-5'>
                <Container className='py-5'>
                    <Row className='align-items-center justify-content-center'>
                        <Col className='text-center'>
                            <h1>Services</h1>
                            <div className='mt-3 d-flex text-center align-items-center justify-content-center'>
                                <img src={H_Icon} alt='keepitech' />
                                <text className='ps-1 mt-1'>Home</text>
                                <text className='px-2 mt-1'>-</text>
                                <text className='mt-1'>Services</text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='py-5 services_project'>
                <Container className='my-5'>
                    <Row className='text-start align-items-center justify-content-center justify-content-lg-between'>
                        <Col lg="6" md="10" sm="10" xs="11" className='mt-4' data-aos="zoom-in-right" data-aos-duration="2500">
                            <img src={Services_main} alt='Services keepitech' width="100%" className='p-3' />
                        </Col>
                        <Col lg="6" md="11" sm="12" xs="11" className='ser_text mt-4'>
                            <h1 className='text_darkblue'>Have A Project In Mind? Let's Connect</h1>
                            <h5 className='text_light'>If you have a project in mind, we would be delighted to connect with you and discuss it further. Our team of experts is well-equipped to handle a wide range of projects, and we are confident that we can provide you with a tailored solution that meets your specific needs. Whether you are looking to develop a new website, design a marketing campaign, or launch a new product, we have the skills and expertise to bring your vision to life. </h5>
                            <Row>
                                <Col xxl="4" sm="5" xs="8" className='mt-3'>
                                    <button className="Home_Btn">Let's Connect &nbsp; ↬</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='my-5 services_bg'>
                <Container>
                    <Row className='align-items-start justify-content-center'>
                        <Col lg="4" md="6" sm="11" className='mt-3 ser_card_col'>
                            <div className='ser_card'>
                                <img src={Web} alt='' height="100" />
                                <h4 className='mt-3'>Web Development</h4>
                                <p>Keepitech offers professional and reliable web development services with over a decade of experience in building web applications.</p>
                                <Row className="py-2 justify-content-start">
                                    <Col xxl="6" xl="7" lg="9" md="8" sm="6" xs="10"><button className="home_review_btn">Learn More &nbsp; ⇒</button></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="11" className='mt-3 ser_card_col'>
                            <div className='ser_card'>
                                <img src={App} alt='' height="100" />
                                <h4 className='mt-3'>App Developement</h4>
                                <p>Our experienced app designers and developers specialize in creating intuitive and feature-rich Android and iOS apps that engage users and drive business growth.</p>
                                <Row className="py-2 justify-content-start">
                                    <Col xxl="6" xl="7" lg="9" md="8" sm="6" xs="10"><button className="home_review_btn">Learn More &nbsp; ⇒</button></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="11" className='mt-3 ser_card_col'>
                            <div className='ser_card'>
                                <img src={UIUX} alt='' height="100" />
                                <h4 className='mt-3'>UI/Ux Design</h4>
                                <p>Crafting visually captivating and user-friendly websites and application that leave a lasting impression.</p>
                                <Row className="py-2 justify-content-start">
                                    <Col xxl="6" xl="7" lg="9" md="8" sm="6" xs="10"><button className="home_review_btn">Learn More &nbsp; ⇒</button></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="11" className='mt-3 ser_card_col'>
                            <div className='ser_card'>
                                <img src={DM} alt='' height="100" />
                                <h4 className='mt-3'>Digital Marketing</h4>
                                <p>Driving growth and visibility for your business through strategic digital marketing solutions.</p>
                                <Row className="py-2 justify-content-start">
                                    <Col xxl="6" xl="7" lg="9" md="8" sm="6" xs="10"><button className="home_review_btn">Learn More &nbsp; ⇒</button></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="11" className='mt-3 ser_card_col'>
                            <div className='ser_card'>
                                <img src={Data} alt='' height="100" />
                                <h4 className='mt-3'>Data Security</h4>
                                <p>Guarding your valuable data with state-of-the-art security measures to keep your business safe.</p>
                                <Row className="py-2 justify-content-start">
                                    <Col xxl="6" xl="7" lg="9" md="8" sm="6" xs="10"><button className="home_review_btn">Learn More &nbsp; ⇒</button></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg="4" md="6" sm="11" className='mt-3 ser_card_col'>
                            <div className='ser_card'>
                                <img src={Seo} alt='' height="100" />
                                <h4 className='mt-3'>Search SEO</h4>
                                <p>Boosting your online presence and rankings through effective SEO strategies.</p>
                                <Row className="py-2 justify-content-start">
                                    <Col xxl="6" xl="7" lg="9" md="8" sm="6" xs="10"><button className="home_review_btn">Learn More &nbsp; ⇒</button></Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default Services;