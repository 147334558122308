import React from "react";
import './footer.css';

import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../Image/Keepitech Name.png";
import Map from "../../Image/Home/Footer/Map.svg";
import Mobile from "../../Image/Home/Footer/Mobile.svg";
import Email from "../../Image/Home/Footer/Email.svg";

import Trial from '../../Image/Home/Trial.svg';
import Trial_N from '../../Image/Home/Trial_N.svg';

import Linkedin1 from "../../Image/Home/Footer/Linkedin1.png";
import Insta1 from "../../Image/Home/Footer/Insta1.png";
import Facebook1 from "../../Image/Home/Footer/Facebook1.png";
import Twitter1 from "../../Image/Home/Footer/Twitter1.png";
import Medium1 from "../../Image/Home/Footer/Medium1.png";

export default function Footer() {
    return (
        <>
            <section className="my-5 py-5">
                <Container className="py-5">
                    <Row className="justify-content-center">
                        <Col xl="11" className="home_trial py-5">
                            <div className="text-center align-items-center">
                                <p className="mt-4 home_trial_yellow">Let's Try! Get Free Support</p>
                                <h1>Start Your 3-Day Free Trial</h1>
                                <text>We can help you to create your dream website for better business revenue.</text>
                            </div>
                            <Row className="mt-3 align-items-center justify-content-center">
                                <Col xl="2" lg="3" md="4" sm="5" xs="6" className="mt-3">
                                    <button className="Home_Btn">Contact With Us</button>
                                </Col>
                                <Col xxl="2" xl="3" lg="3" md="4" sm="5" xs="6" className="mt-3 d-flex align-items-center">
                                    <img src={Trial} alt="" />
                                    <text className="ms-3">How It Works</text>
                                </Col>
                            </Row>
                            <Row className="my-3 align-items-center justify-content-center">
                                <Col xxl="2" lg="3" md="4" sm="5" xs="8" className="mt-3 d-flex">
                                    <img src={Trial_N} alt="" />
                                    <p className="mb-0 ms-2">Free 3-day trial</p>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5" xs="8" className="mt-3 d-flex">
                                    <img src={Trial_N} alt="" />
                                    <p className="mb-0 ms-2">Support 24/7</p>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5" xs="8" className="mt-3 d-flex">
                                    <img src={Trial_N} alt="" />
                                    <p className="mb-0 ms-2">Cancel anytime</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pt-5 pb-3 mainFooter">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl="3" lg="3" md="5" sm="10" xs="10" className="mt-5">
                            <Row>
                                <Col xxl="4" xl="5" md="6" sm="5" xs="6">
                                    <img src={Logo} alt="Keepitech" width="90%" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-3">
                                    <p className="mb-0 text_light">Elevate Your Digital Experience</p>
                                    <p className="mb-0 text_light">With KeepiTech.</p>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col xl="2" md="3" xs="2" className="mt-3">
                                    <div className="footer_Social">
                                        <a href="https://www.linkedin.com/company/keepitech/" target="_blank"><img src={Linkedin} alt="Linkedin" width="20" /></a>
                                    </div>
                                </Col>
                                <Col xl="2" md="3" xs="2" className="mt-3">
                                    <div className="footer_Social">
                                        <a href="https://www.instagram.com/keepitech/" target="_blank"><img src={Insta} alt="" width="20" /></a>
                                    </div>
                                </Col>
                                <Col xl="2" md="3" xs="2" className="mt-3">
                                    <div className="footer_Social">
                                        <a href="/"><img src={Facebook} alt="" width="10" /></a>
                                    </div>
                                </Col>
                                <Col xl="2" md="3" xs="2" className="mt-3">
                                    <div className="footer_Social">
                                        <a href="/"><img src={Twitter} alt="" width="20" /></a>
                                    </div>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col xl="3" lg="3" md="5" sm="5" xs="10" className="mt-5 footer_text">
                            <h5>Services</h5>
                            <Row className="mt-4">
                                <Col>
                                    <a href="/Services" className="mb-0">Web Development</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-3">
                                    <a href="/Services" className="mb-0">Mobile Development</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-3">
                                    <a href="/Services" className="mb-0">E-Commerce Solutions</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-3">
                                    <a href="/Services" className="mb-0">ERP And CRM</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="3" lg="3" md="5" sm="5" xs="10" className="mt-5 footer_text">
                            <h5>Quick Links</h5>
                            <Row className="mt-4">
                                <Col><a href="/About">About Us</a></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col><a href="/Contact">Get In Touch</a></Col>
                            </Row>
                        </Col>
                        <Col xl="3" lg="3" md="5" sm="10" xs="10" className="mt-5 footer_text">
                            <h5>Contact Me</h5>
                            <Row className="mt-4">
                                <Col className="d-flex align-items-start">
                                    <img src={Map} alt="keepitech" width="22" height="22" />
                                    <p className="mb-0 ms-2">19, Mahalaxmi Soc, Behind Tulsi Hotel, Nan Varachha, Surat, Gujarat - 395006</p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="d-flex align-items-start">
                                    <img src={Email} alt="keepitech" width="22" height="22" />
                                    <p className="mb-0 ms-2"><a href="mailto:contact@keepitech.com">contact@keepitech.com</a></p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="d-flex align-items-start">
                                    <img src={Mobile} alt="keepitech" width="22" height="22" />
                                    <div>
                                        <p className="mb-1 ms-2"><a href="tel:+916353449734">+91 63534 49734</a></p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3 text-center align-items-center justify-content-center">
                        <Col>
                            <div className="footer_icon text-center align-items-center justify-content-center">
                                <div className="box">
                                    <div className="item">
                                        <a href="https://www.linkedin.com/company/keepitech/" target="_blank" rel="noreferrer"><img src={Linkedin1} alt="keepitech linkedin" width="90" /></a>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="item">
                                        <a href="https://www.instagram.com/keepitech/" target="_blank" rel="noreferrer"><img src={Insta1} alt="keepitech instagram" width="90" /></a>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="item">
                                        <a href="/" target="_blank"><img src={Facebook1} alt="keepitech facebook" width="90" /></a>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="item">
                                        <a href="https://twitter.com/KeepiTech" target="_blank" rel="noreferrer"><img src={Twitter1} alt="keepitech twitter" width="90" /></a>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="item">
                                        <a href="/" target="_blank"><img src={Medium1} alt="Linkedin" width="90" /></a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-center justify-content-center">
                        <Col xl="12">
                            <hr />
                            <h6 className="py-3 mb-0">© 2024. All rights reserved by Keepitech.</h6>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
};
