import React from 'react';
import './career.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import H_Icon from '../../Image/Career/Home_Icon.svg';
import Line_icon from '../../Image/Home/Line_icon.svg';
import Job from '../../Image/Career/Job.svg';
import Location from '../../Image/Career/Location.svg';
import Experience from '../../Image/Career/Experience.svg';
import Opening from '../../Image/Career/Opening.svg';
import Days from '../../Image/Career/Icons/Days.webp';
import Paid_leave from '../../Image/Career/Icons/Paid leave.webp';
import Overtime from '../../Image/Career/Icons/Overtime.webp';
import Health from '../../Image/Career/Icons/Health.webp';
import Personal_loan from '../../Image/Career/Icons/Personal loan.webp';
import Fun from '../../Image/Career/Icons/Fun.webp';
import Employee_Training from '../../Image/Career/Icons/Employee Training.webp';
import Work_Environment from '../../Image/Career/Icons/Work Environment.webp';

function Career() {
    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>KeepiTech - Career</title>
                <link rel="canonical" href="https://keepitech.com/Career" />
            </Helmet>

            <section className='Career_Main py-5'>
                <Container className='py-5'>
                    <Row className='align-items-center justify-content-center'>
                        <Col className='text-center'>
                            <h1>Career</h1>
                            <div className='mt-3 d-flex text-center align-items-center justify-content-center'>
                                <img src={H_Icon} alt='keepitech' />
                                <text className='ps-1 mt-1'>Home</text>
                                <text className='px-2 mt-1'>-</text>
                                <text className='mt-1'>Career</text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='py-5 career_ser_bg'>
                <Container className='my-5'>
                    <Row>
                        <Col className='mt-4 Aboutus'>
                            <div className="mx-3 align-items-center Home_sec2_title d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">Get free Consultation for your productive business</p>
                            </div>
                            <h1 className='mx-3 text_darkblue mt-3'>We Care For <span>Our Team</span>.</h1>
                            <h5 className='mx-3 text_light mt-2'>Research suggests that a person spends more than 1/3rd of his life at work.</h5>
                        </Col>
                    </Row>
                    <Row className='mt-3 align-items-start justify-content-evenly'>
                        <Col xl="3" lg="4" md="6" sm="9" xs="11" className='mt-4 text-center align-items-center'>
                            <div className='career_box pt-4'>
                                <div className='career_box_img d-flex'>
                                    <img src={Days} alt='5 day work' width="50" />
                                </div>
                                <h6 className='py-4'>Alternate Saturday Off</h6>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="6" sm="9" xs="11" className='mt-4 text-center align-items-center'>
                            <div className='career_box pt-4'>
                                <div className='career_box_img d-flex'>
                                    <img src={Paid_leave} alt='paid Leaves' width="45" />
                                </div>
                                <h6 className='py-4'>Paid Leaves</h6>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="6" sm="9" xs="11" className='mt-4 text-center align-items-center'>
                            <div className='career_box pt-4'>
                                <div className='career_box_img d-flex'>
                                    <img src={Overtime} alt='overtime Pay' width="50" />
                                </div>
                                <h6 className='py-4'>Overtime Pay</h6>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="6" sm="9" xs="11" className='mt-4 text-center align-items-center'>
                            <div className='career_box pt-4'>
                                <div className='career_box_img d-flex'>
                                    <img src={Health} alt='health insurance' width="50" />
                                </div>
                                <h6 className='py-4'>Health Insurance</h6>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="6" sm="9" xs="11" className='mt-4 text-center align-items-center'>
                            <div className='career_box pt-4'>
                                <div className='career_box_img d-flex'>
                                    <img src={Fun} alt='fun activities' width="50" />
                                </div>
                                <h6 className='py-4'>Fun Activities</h6>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="6" sm="9" xs="11" className='mt-4 text-center align-items-center'>
                            <div className='career_box pt-4'>
                                <div className='career_box_img d-flex'>
                                    <img src={Personal_loan} alt='personal loan' width="50" />
                                </div>
                                <h6 className='py-4'>Personal Loan</h6>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="6" sm="9" xs="11" className='mt-4 text-center align-items-center'>
                            <div className='career_box pt-4'>
                                <div className='career_box_img d-flex'>
                                    <img src={Employee_Training} alt='employee training' width="50" />
                                </div>
                                <h6 className='py-4'>Employee Training</h6>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="6" sm="9" xs="11" className='mt-4 text-center align-items-center'>
                            <div className='career_box pt-4'>
                                <div className='career_box_img d-flex'>
                                    <img src={Work_Environment} alt='positive work environment' width="50" />
                                </div>
                                <h6 className='py-4'>Positive Work Environment</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='py-5 career_bg'>
                <Container className='my-5'>
                    <Row className='text-start align-items-center justify-content-center justify-content-lg-evenly'>
                        <Col className='mt-4 Aboutus' style={{ backgroundColor: "white" }}>
                            <div className="mx-3 align-items-center Home_sec2_title d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">OUR JOBS</p>
                            </div>
                            <h1 className='mx-3 text_darkblue mt-3'>Current Available <span>Positions</span> at Quiety.</h1>
                            <h5 className='mx-3 text_light mt-4'>This is exactly where Keepitech stands apart from the others. To evolve with innovations, we always welcome and recognise Keepitech who come up with great ideas. Voice of employees matters to us. Keepitech has the global presence, so grab the opportunity to work with top MNCs of the world. Join us and give yourself an opportunity to take your career to the newest heights.</h5>
                        </Col>
                    </Row>
                    <Row className='mt-5 align-items-start justify-content-evenly'>
                        <Col xl="5" lg="6" className='mt-4'>
                            <div className='career_jobs'>
                                <div className='jobs_1'>

                                    <div className='d-flex align-items-center'>
                                        <img src={Job} alt='keepitech job React Js Developer' width="20" />
                                        <text className='mb-0 ms-2'>Full Time - Remote</text>
                                    </div>
                                    <h3 className='my-3'>React Js Developer</h3>
                                    <div className='mt-4 d-flex align-items-center'>
                                        <img src={Location} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Location : &nbsp; Surat</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Experience} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Experience : &nbsp; 1 Years - 3 Years</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Opening} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Openings : &nbsp; 02</p>
                                    </div>
                                    <Row className="pt-4 justify-content-start">
                                        <Col xl="5" lg="6" sm="5" xs="9">
                                            <button className="career_jobs_btn">Apply Now &nbsp; ⇒</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xl="5" lg="6" className='mt-4'>
                            <div className='career_jobs'>
                                <div className='jobs_2'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Job} alt='keepitech job Flutter Developer' width="20" />
                                        <text className='mb-0 ms-2'>Full Time - Remote</text>
                                    </div>
                                    <h3 className='my-3'>Flutter Developer</h3>
                                    <div className='mt-4 d-flex align-items-center'>
                                        <img src={Location} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Location : &nbsp; Surat</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Experience} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Experience : &nbsp; 6 Months - 1 Years</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Opening} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Openings : &nbsp; 03</p>
                                    </div>
                                    <Row className="pt-4 justify-content-start">
                                        <Col xl="5" lg="6" sm="5" xs="9">
                                            <button className="career_jobs_btn">Apply Now &nbsp; ⇒</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xl="5" lg="6" className='mt-4'>
                            <div className='career_jobs'>
                                <div className='jobs_3'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Job} alt='keepitech job Node Js Developer' width="20" />
                                        <text className='mb-0 ms-2'>Full Time - Remote</text>
                                    </div>
                                    <h3 className='my-3'>Node Js Developer</h3>
                                    <div className='mt-4 d-flex align-items-center'>
                                        <img src={Location} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Location : &nbsp; Surat</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Experience} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Experience : &nbsp; 1 Years - 2 Years</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Opening} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Openings : &nbsp; 02</p>
                                    </div>
                                    <Row className="pt-4 justify-content-start">
                                        <Col xl="5" lg="6" sm="5" xs="9">
                                            <button className="career_jobs_btn">Apply Now &nbsp; ⇒</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xl="5" lg="6" className='mt-4'>
                            <div className='career_jobs'>
                                <div className='jobs_4'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Job} alt='keepitech job UI/UX Designer' width="20" />
                                        <text className='mb-0 ms-2'>Full Time - Remote</text>
                                    </div>
                                    <h3 className='my-3'>UI/UX Designer</h3>
                                    <div className='mt-4 d-flex align-items-center'>
                                        <img src={Location} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Location : &nbsp; Surat</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Experience} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Experience : &nbsp; 6 Months - 1 Years</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Opening} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Openings : &nbsp; 01</p>
                                    </div>
                                    <Row className="pt-4 justify-content-start">
                                        <Col xl="5" lg="6" sm="5" xs="9">
                                            <button className="career_jobs_btn">Apply Now &nbsp; ⇒</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xl="5" lg="6" className='mt-4'>
                            <div className='career_jobs'>
                                <div className='jobs_5'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Job} alt='keepitech job BDE' width="20" />
                                        <text className='mb-0 ms-2'>Full Time - Remote</text>
                                    </div>
                                    <h3 className='my-3'>Business Development Executive</h3>
                                    <div className='mt-4 d-flex align-items-center'>
                                        <img src={Location} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Location : &nbsp; Surat</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Experience} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Experience : &nbsp; 1 Years - 2 Years</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Opening} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Openings : &nbsp; 02</p>
                                    </div>
                                    <Row className="pt-4 justify-content-start">
                                        <Col xl="5" lg="6" sm="5" xs="9">
                                            <button className="career_jobs_btn">Apply Now &nbsp; ⇒</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xl="5" lg="6" className='mt-4'>
                            <div className='career_jobs'>
                                <div className='jobs_6'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Job} alt='keepitech job React Native Devloper' width="20" />
                                        <text className='mb-0 ms-2'>Full Time - Remote</text>
                                    </div>
                                    <h3 className='my-3'>React Native Devloper</h3>
                                    <div className='mt-4 d-flex align-items-center'>
                                        <img src={Location} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Location : &nbsp; Surat</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Experience} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Experience : &nbsp; 1 Years - 2 Years</p>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <img src={Opening} alt='keepitech job' width="20" />
                                        <p className='mb-0 ms-2'>Openings : &nbsp; 01</p>
                                    </div>
                                    <Row className="pt-4 justify-content-start">
                                        <Col xl="5" lg="6" sm="5" xs="9">
                                            <button className="career_jobs_btn">Apply Now &nbsp; ⇒</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Career;