import React, { useEffect, useState } from 'react';
import './contact.css';
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';
import AOS from 'aos';
import toast, { Toaster } from 'react-hot-toast';

import H_Icon from '../../Image/Contact/Home_Icon.svg';
import Address from '../../Image/Contact/Address.svg';
import Call from '../../Image/Contact/Call.svg';
import Email from '../../Image/Contact/Email.svg';
import men from '../../Image/Contact/men.svg';
import Chat from '../../Image/Contact/Chat.webp';

import Linkedin from '../../Image/Home/Footer/Linkedin.svg';
import Facebook from '../../Image/Home/Footer/Facebook.svg';
import Twitter from '../../Image/Home/Footer/Twitter.svg';
import Insta from '../../Image/Home/Footer/Insta.svg';

import axios from 'axios';

function Contact() {

  AOS.init({ easing: 'ease' });

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [userIP, setUserIP] = useState('');

  const serviceId = 'service_rv2d50z';
  const templateId = 'template_enc1ajs';
  const userId = 's_RBMVu1LCuz6DvT4';

  const templateParams = {
    from_name: name,
    from_email: email,
    from_message: message,
    from_number: number,
    from_city: city,
    from_state: state,
    from_country: country,
    from_code: countryCode,
    from_IP: userIP,
  };

  const sendFn = () => {

    if (name && email && message && number) {
      emailjs.send(serviceId, templateId, templateParams, userId)
        .then((response) => {
          notify_success("Email sent successfully");
          setName("");
          setEmail("");
          setMessage("");
          setNumber("");
          setCity("");
          setState("");
          setCountry("");
          setCountryCode("");
          setUserIP("");
        })
        .catch((error) => {
          notify_error("Error sending email", error);
        });
    }
    else {
      notify_error('All fields are mandatory.');
    }
  }

  useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then(response => {
        setCity(response.data.city);
        setState(response.data.region);
        setCountry(response.data.country_name);
        setUserIP(response.data.ip);
        setCountryCode(response.data.country_calling_code);
      })
      .catch(error => {
        console.error('Error fetching location information', error);
      });
  }, []);

  const notify_success = (e) => toast.success(e, { style: { borderRadius: '10px', background: '#333', color: '#fff' } });
  const notify_error = (e) => toast.error(e, { style: { borderRadius: '10px', background: '#333', color: '#fff' } });

  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>KeepiTech - Quick Contact</title>
        <link rel="canonical" href="https://keepitech.com/Contact" />
      </Helmet>

      <section className='Contact_Main py-5'>
        <Container className='py-5'>
          <Row className='align-items-center justify-content-center'>
            <Col className='text-center'>
              <h1>Quick Contact</h1>
              <div className='mt-3 d-flex text-center align-items-center justify-content-center'>
                <img src={H_Icon} alt='keepitech' />
                <text className='ps-1 mt-1'>Home</text>
                <text className='px-2 mt-1'>-</text>
                <text className='mt-1'>Contact</text>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className='my-5 py-5'>
        <Row className='align-items-start justify-content-center'>
          <Col lg="6">
            <Row className='text-start'>
              <Col>
                <h1>Get in touch with us</h1>
                <p className='text_light'>Let’s talk about your website or project. Send us a message and we will be in touch within one business day.</p>
              </Col>
            </Row>
            <div className='Contact_sub'>
              <Row className='align-items-start justify-content-start'>
                <h4>India Branch:</h4>
                <Col xl="6" lg="10" md="6">
                  <Row className='mt-4 d-flex align-items-start justify-content-start'>
                    <Col lg="2" md="2" sm="1" xs="2">
                      <div className='Contect_img'>
                        <img src={Address} alt='keepitech Address' />
                      </div>
                    </Col>
                    <Col lg="10" md="10" sm="10" xs="10" className='ps-3 ps-xl-4 ps-lg-1 ps-sm-4'>
                      <h6>Address</h6>
                      <text>19, Mahalaxmi Soc, Behind Tulsi Hotel, Nan Varachha, Surat, Gujarat - 395006</text>
                    </Col>
                  </Row>
                </Col>
                <Col xl="6" lg="10" md="6">
                  <Row className='mt-4 d-flex align-items-start justify-content-start'>
                    <Col lg="2" md="2" sm="1" xs="2">
                      <div className='Contect_img'>
                        <img src={Call} alt='keepitech Address' />
                      </div>
                    </Col>
                    <Col lg="10" md="10" sm="10" xs="10" className='ps-3 ps-xl-4 ps-lg-1 ps-sm-4'>
                      <h6>Call Us</h6>
                      <p className='mb-0'>+91 63534 49734</p>
                    </Col>
                  </Row>
                  <Row className='mt-4 d-flex align-items-start justify-content-start'>
                    <Col lg="2" md="2" sm="1" xs="2">
                      <div className='Contect_img'>
                        <img src={Email} alt='keepitech Address' />
                      </div>
                    </Col>
                    <Col lg="10" md="10" sm="10" xs="10" className='ps-3 ps-xl-4 ps-lg-1 ps-sm-4'>
                      <h6>Email Us</h6>
                      <text>contact@keepitech.com</text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className='Contact_sub Contact_Men_top'>
              <img src={men} alt='keepitech' className='Contact_Men_1' width={100} data-aos="zoom-in-up" data-aos-offset="50" data-aos-duration="2000" />
              <Row className='align-items-start justify-content-center'>
                <Col className='mt-3 pb-2'>
                  <h4>Follow Us:</h4>
                  <Row>
                    <Col xl="1" lg="2" md="1" xs="2" className="mt-2">
                      <div className="Contact_Social">
                        <a href="https://www.linkedin.com/company/keepitech/" target="_blank" rel="noreferrer"><img src={Linkedin} alt="Linkedin" width="20" /></a>
                      </div>
                    </Col>
                    <Col xl="1" lg="2" md="1" xs="2" className="mt-2">
                      <div className="Contact_Social">
                        <a href="https://www.instagram.com/keepitech/" target="_blank" rel="noreferrer"><img src={Insta} alt="" width="20" /></a>
                      </div>
                    </Col>
                    <Col xl="1" lg="2" md="1" xs="2" className="mt-2">
                      <div className="Contact_Social">
                        <a href="/"><img src={Facebook} alt="" width="10" /></a>
                      </div>
                    </Col>
                    <Col xl="1" lg="2" md="1" xs="2" className="mt-2">
                      <div className="Contact_Social">
                        <a href="https://twitter.com/KeepiTech" target="_blank" rel="noreferrer"><img src={Twitter} alt="" width="20" /></a>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg="6" className='Contact_sub1'>
            <div className='mt-5 Contact_sub'>
              <img src={Chat} alt='keepitech' className='Contact_Men' width={300} data-aos="zoom-in-up" data-aos-offset="100" data-aos-duration="2000" />
              <Row className='mt-3'>
                <Col><h2>Let’s discuss the project</h2></Col>
              </Row>
              <Row>
                <Col className='mt-3'>
                  <label>Full Name</label>
                  <input type="text" placeholder='Enter your name' className='mt-1' value={name} onChange={(e) => setName(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col className='mt-3'>
                  <label>Email Address</label>
                  <input type="text" placeholder='Enter your email' className='mt-1' value={email} onChange={(e) => setEmail(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col className='mt-3'>
                  <label>Contact Number</label>
                  <input type="text" placeholder='Enter your number' className='mt-1' value={number} onChange={(e) => setNumber(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col className='mt-3'>
                  <label>Description</label>
                  <textarea type='text' placeholder='Enter your message' className='mt-1' value={message} onChange={(e) => setMessage(e.target.value)} />
                </Col>
              </Row>
              <Row>
                <Col xl="5" lg="6" md="4" sm="5" xs="8">
                  <button className="mt-4 Home_Btn" onClick={() => sendFn()}>Send A Message</button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <Toaster
        position="bottom-left"
        reverseOrder={false}
      />

    </>
  )
}

export default Contact;