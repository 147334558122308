import React from 'react';
import './about.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import AOS from 'aos';

import H_Icon from '../../Image/Contact/Home_Icon.svg';
import Aboutus from '../../Image/About/Aboutus.svg';
import Line_icon from '../../Image/Home/Line_icon.svg';
import List_Item from '../../Image/About/List_Item.svg';
import Experience from '../../Image/About/Experience.svg';
import Clients from '../../Image/About/Clients.svg';
import Projects from '../../Image/About/Projects.svg';
import Resources from '../../Image/About/Resources.svg';
import GlobalOffice from '../../Image/About/Global Offices.svg';

function About() {

    AOS.init({ easing: 'ease' });

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>KeepiTech - About Us</title>
                <link rel="canonical" href="https://keepitech.com/About" />
            </Helmet>

            <section className='Contact_Main py-5'>
                <Container className='py-5'>
                    <Row className='align-items-center justify-content-center'>
                        <Col className='text-center'>
                            <h1>About Us</h1>
                            <div className='mt-3 d-flex text-center align-items-center justify-content-center'>
                                <img src={H_Icon} alt='keepitech' />
                                <text className='ps-1 mt-1'>Home</text>
                                <text className='px-2 mt-1'>-</text>
                                <text className='mt-1'>About Us</text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='py-5'>
                <Container className='my-5 Aboutus_bg' fluid>
                    <Row className='text-start align-items-center justify-content-center justify-content-lg-evenly'>
                        <Col xxl="5" lg="6" md="10" xs="11" className='mt-4' data-aos="zoom-in-right" data-aos-duration="2500">
                            <img src={Aboutus} alt='About keepitech' width="100%" className='p-3' />
                        </Col>
                        <Col xxl="5" lg="6" md="10" xs="11" className='mt-4 Aboutus'>
                            <div className="align-items-center Home_sec2_title d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">WHO WE ARE</p>
                            </div>
                            <h1 className='text_darkblue mt-3'>We provide perfect it <span> solutions & technology </span> for any startups.</h1>
                            <h5 className='text_light mt-4'>Keepitech Is An Indian Information Technology Company That Provides Global Business Consulting And Information Technology Services. We Helps Businesses To Renew & Improve Existing Conditions So That Their Business Can Achieve Higher Efficiencies And Stay Relevant According To Current Times.</h5>
                            <Row className='mt-3'>
                                <Col sm="6" className='mt-3 d-flex align-items-center'>
                                    <img src={List_Item} alt='' />
                                    <h5 className='ms-3 mb-0'>Internal Networking</h5>
                                </Col>
                                <Col sm="6" className='mt-3 d-flex align-items-center'>
                                    <img src={List_Item} alt='' />
                                    <h5 className='ms-3 mb-0'>Tech Services</h5>
                                </Col>
                            </Row>
                            <h5 className='text_light mt-4'>We Are A Global Creative Agency That Combines Design Expertise With Technology And Intelligence To Revolutionize Your Business.</h5>
                            <Row>
                                <Col xxl="4" sm="5" xs="8" className='mt-3'>
                                    <button className="Home_Btn">Discover More &nbsp; ↬</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container fluid className='mb-5'>
                <Row className='align-items-start justify-content-center'>
                    <Col md="2" sm="4" xs="6" className='mt-4'>
                        <div className='about_box1 py-4 text-center align-items-center'>
                            <img src={Experience} alt='Experience keepitech' />
                            <h2 className='mt-3'>3</h2>
                            <h5 className='mt-3'>Years of Experience</h5>
                        </div>
                    </Col>
                    <Col md="2" sm="4" xs="6" className='mt-4'>
                        <div className='about_box2 py-4 text-center align-items-center'>
                            <img src={Clients} alt='Clients keepitech' />
                            <h2 className='mt-3'>18</h2>
                            <h5 className='mt-3'>Clients</h5>
                        </div>
                    </Col>
                    <Col md="2" sm="4" xs="6" className='mt-4'>
                        <div className='about_box3 py-4 text-center align-items-center'>
                            <img src={Projects} alt='Projects keepitech' />
                            <h2 className='mt-3'>25</h2>
                            <h5 className='mt-3'>Projects</h5>
                        </div>
                    </Col>
                    <Col md="2" sm="4" xs="6" className='mt-4'>
                        <div className='about_box4 py-4 text-center align-items-center'>
                            <img src={Resources} alt='Resources keepitech' />
                            <h2 className='mt-3'>50</h2>
                            <h5 className='mt-3'>Resources</h5>
                        </div>
                    </Col>
                    <Col md="2" sm="4" xs="6" className='mt-4'>
                        <div className='about_box5 py-4 text-center align-items-center'>
                            <img src={GlobalOffice} alt='GlobalOffice keepitech' />
                            <h2 className='mt-3'>2</h2>
                            <h5 className='mt-3'>Global Office</h5>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default About