import React from "react";
import './home.css';
import { Card, Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';

import home from '../Image/Home/Home 1.svg';
import home2 from '../Image/Home/Home 2.svg';
import bg1 from '../Image/Home/bg 1.svg';
import icon1 from '../Image/Home/Home_icon1.svg';
import icon2 from '../Image/Home/Home_icon2.svg';
import icon3 from '../Image/Home/Home_icon3.svg';
import Line_icon from '../Image/Home/Line_icon.svg';
import Home_true from '../Image/Home/Home_true.svg';
import Home_About from '../Image/Home/Home_About.svg';
import Home_Done from '../Image/Home/Home_Done.svg';
import Star5 from '../Image/Home/5_Star.svg';
import Star4_5 from '../Image/Home/4.5_Star.svg';
import Review_Quote from '../Image/Home/Review_Quote.svg';
import Review_Man from '../Image/Home/Review_Man.svg';
import Review_Woman from '../Image/Home/Review_Woman.svg';
import home_faq from '../Image/Home/home_faq.svg';
import Web_development from '../Image/Home/Web development.svg';
import App_development from '../Image/Home/App development.svg';
import Software_development from '../Image/Home/Software development.svg';
import Product_engineering from '../Image/Home/Product engineering.svg';
import { Helmet } from "react-helmet";

export default function Home() {

    AOS.init({ easing: 'ease' });

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>KeepiTech - Home</title>
                <link rel="canonical" href="https://keepitech.com/" />
            </Helmet>

            <section className="Home_Main">
                <Container className="ps-0" fluid>
                    <Row className="align-items-center justify-content-center">
                        <Col xxl="6" lg="7" md="10" sm="11">
                            <div className="Home_MainText d-flex align-items-center">
                                <div className="mt-3">
                                    <div className="ball mt-5"></div>
                                    <div className="ball mt-4"></div>
                                    <div className="ball mt-4"></div>
                                    <div className="ball mt-4"></div>
                                    <div className="ball mt-4"></div>
                                    <div className="ball mt-4"></div>
                                    <div className="ball mt-4"></div>
                                </div>
                                <div>
                                    <div className="Home_bgText">
                                        <div className="animate three">
                                            <span>K</span><span>E</span><span>E</span><span>P</span><span>I</span><span>T</span><span>E</span><span>C</span><span>H</span>
                                        </div>
                                    </div>
                                    <div className="Home_MainText_h1">
                                        <h1>Provide Best It</h1>
                                        <h1>Services For Your</h1>
                                        <h1>Business</h1>
                                    </div>
                                </div>
                            </div>
                            <img src={bg1} alt="keepitech" className="home_lastbox" width="50%" />
                        </Col>
                        <Col xxl="6" lg="5" md="8" sm="8" xs="11" className="d-flex" data-aos="zoom-in-up" data-aos-offset="100" data-aos-duration="2500">
                            <img src={home} alt="keepitech" width="80%" className="m-auto" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="Home_Features py-4">
                <Container className="pt-5 Home_font" fluid>
                    <Row className="pt-5 pb-3 text-center justify-content-center">
                        <Col xxl="6" xl="8" lg="10" md="11" className="Home_Sec1">
                            <h1>With all the Features You Need</h1>
                            <h5 className="m-auto">Credibly grow premier ideas rather than bricks-and-clicks strategic theme areas distributed for stand-alone web-readiness.</h5>
                        </Col>
                    </Row>
                    <Row className="mt-5 text-start align-items-center justify-content-center"></Row>
                </Container>

                <Container className="pb-5 Home_font">
                    <Row className="mb-5 pb-5 align-items-center justify-content-center">
                        <Col xl="4" lg="6" md="6" sm="11" className="mt-4 Home_sec1_card">
                            <div className="Home_sec1_cardBorder1">
                                <img src={icon1} alt="it" className="mb-4" />
                                <h3>Good Performance</h3>
                                <p>Appropriately grow competitive rather leadership the leadership processes sound without state.</p>
                            </div>
                        </Col>
                        <Col xl="4" lg="6" md="6" sm="11" className="mt-4 Home_sec1_card">
                            <div className="Home_sec1_cardBorder1">
                                <img src={icon2} alt="it" className="mb-4" />
                                <h3>Highly Secure</h3>
                                <p>Appropriately grow competitive rather leadership the leadership processes sound without state.</p>
                            </div>
                        </Col>
                        <Col xl="4" lg="6" md="8" sm="11" className="mt-4 Home_sec1_card">
                            <div className="Home_sec1_cardBorder1">
                                <img src={icon3} alt="it" className="mb-4" />
                                <h3>Fast Development</h3>
                                <p>Appropriately grow competitive rather leadership the leadership processes sound without state.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container className="my-5 py-5 Home_WhatWeDo" fluid>
                <Row className="pb-4 align-items-center justify-content-center justify-content-xxl-evenly">
                    <Col xxl="5" xl="6" lg="6" md="10" sm="11" className="Home_sec2 mt-4">
                        <div className="align-items-center Home_sec2_title d-flex">
                            <img src={Line_icon} alt="keepitech" />
                            <p className="mb-0 ps-2">WHAT WE DO</p>
                        </div>
                        <h1 className="mt-3">Digital data consulting for your Business Growth</h1>
                        <h5 className="mt-3">Sed ut perspiciatis unde omnis natus error sity voluptatem accusa ntium doloremque laudantie totam rem aperiamea queipsa quae abillonven tore veritatis.</h5>
                        <div className="mt-5 d-flex align-items-center Home_sec2_text">
                            <img src={Home_true} alt="it" />
                            <p className="mb-0 ms-2">In Database Optimized Algorithms</p>
                        </div>
                        <div className="mt-3 d-flex align-items-center Home_sec2_text">
                            <img src={Home_true} alt="it" />
                            <p className="mb-0 ms-2">Access any data Flexible and easily</p>
                        </div>
                        <Row>
                            <Col xxl="3" xl="4" lg="5" md="4" sm="4" xs="6">
                                <button className="mt-5 Home_Btn">KNOW US MORE</button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl="5" xl="5" lg="6" md="8" sm="9" xs="10" data-aos="zoom-in-left" data-aos-offset="300" data-aos-duration="2500">
                        <img src={home2} alt="keepitech" className="home_sec2img text-end" />
                    </Col>
                </Row>
            </Container>

            <section className="home_Sec3">
                <Container className="mt-5 py-5">
                    <Row className="pt-5">
                        <Col className="Home_sec2 text-center">
                            <div className="text-center align-items-center justify-content-center Home_sec2_title d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">OUR SERVICES</p>
                            </div>
                            <h1 className="mt-2">Our Proposition Data Science <br /> and Analytics Services</h1>
                        </Col>
                    </Row>
                    <Row className="pb-5 pt-2 text-center align-items-center justify-content-center">
                        <Col lg="3" sm="6" xs="10" className="mt-4" data-aos="fade-up" data-aos-offset="500" data-aos-duration="1000">
                            <Card className="home_Sec3_Card">
                                <Card.Img variant="top" src={Web_development} className="p-3" />
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title>Web Development</Card.Title>
                                    <Card.Text className="mb-0">Expert web development services designed for optimal SEO performance and user experience.</Card.Text>
                                    <Row className="mt-auto justify-content-center">
                                        <Col xl="9" lg="10" md="8" xs="10">
                                            <button className="Home_Btn">READ MORE</button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="3" sm="6" xs="10" className="mt-4" data-aos="fade-up" data-aos-offset="550" data-aos-duration="1000">
                            <Card className="home_Sec3_Card">
                                <Card.Img variant="top" src={App_development} className="p-4" />
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title>App Development</Card.Title>
                                    <Card.Text className="mb-0">App development creates software for mobile or desktop devices.</Card.Text>
                                    <Row className="mt-auto justify-content-center">
                                        <Col xl="9" lg="10" md="8" xs="10">
                                            <button className="Home_Btn">READ MORE</button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="3" sm="6" xs="10" className="mt-4" data-aos="fade-up" data-aos-offset="600" data-aos-duration="1000">
                            <Card className="home_Sec3_Card">
                                <Card.Img variant="top" src={Product_engineering} className="p-4" />
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title>Product Engineering</Card.Title>
                                    <Card.Text className="mb-0">Product engineering focuses on creating and improving products for customer satisfaction and market success.</Card.Text>
                                    <Row className="mt-auto justify-content-center">
                                        <Col xl="9" lg="10" md="8" xs="10">
                                            <button className="Home_Btn">READ MORE</button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="3" sm="6" xs="10" className="mt-4" data-aos="fade-up" data-aos-offset="650" data-aos-duration="1000">
                            <Card className="home_Sec3_Card">
                                <Card.Img variant="top" src={Software_development} className="" />
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title>Software Development</Card.Title>
                                    <Card.Text className="mb-0">Software development creates computer programs to solve problems and add functionality.</Card.Text>
                                    <Row className="mt-auto justify-content-center">
                                        <Col xl="9" lg="10" md="8" xs="10">
                                            <button className="Home_Btn">READ MORE</button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="Home_Sec_About py-5">
                <Container className="py-5" fluid>
                    <Row className="align-items-center justify-content-center">
                        <Col xl="5" lg="5" md="8" sm="9" xs="10" data-aos="zoom-in-right" data-aos-offset="400" data-aos-duration="2500">
                            <img src={Home_About} width="100%" alt="keepitech" />
                        </Col>
                        <Col xl="5" lg="6" md="10" sm="11" className="Home_sec2 text-start">
                            <div className="text-start align-items-center Home_sec2_title d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">About Us</p>
                            </div>
                            <h1 className="mt-2">We Are Increasing Business Success With Technology</h1>
                            <h5>Deliver beautiful emails that get opened and clicked every time. Personalized content to every subscriber. Fully customizable templates. Build relationships that lead to conversions.</h5>
                            <h4 className="mt-5">Top Features</h4>
                            <Row className="Home_Sec_About_last mt-3">
                                <Col>
                                    <div className="d-flex align-items-center">
                                        <img src={Home_Done} alt="keepitech" />
                                        <h4 className="mt-1">Drag & Drop Newsletter Editor</h4>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src={Home_Done} alt="keepitech" />
                                        <h4 className="mt-1">Subject Line A/B Testing</h4>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex align-items-center">
                                        <img src={Home_Done} alt="keepitech" />
                                        <h4 className="mt-1">Content A/B Testing</h4>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src={Home_Done} alt="keepitech" />
                                        <h4 className="mt-1">List Segmentation</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="4" lg="6" md="4" sm="4" xs="7" className="mt-4">
                                    <button className="Home_Btn">Get Started Now</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section className="home_Project">
                <Container className="mt-5 py-5" fluid>
                    <Row className="pt-5 pb-3">
                        <Col className="Home_sec2 text-center">
                            <div className="text-center align-items-center justify-content-center Home_sec2_title d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">OUR PROJECT</p>
                            </div>
                            <h1 className="mt-2">Our Recent Launched Projects <br /> Available into Market</h1>
                        </Col>
                    </Row>
                    <Row className="pb-3 align-items-center justify-content-xxl-evenly justify-content-center">
                        <Col xxl="2" xl="3" lg="4" md="5" sm="5" xs="10" className="mt-4">
                            <img src={Project1} alt="" width="100%" />
                            <div className="home_projectCard mx-3">
                                <p className="mb-0 pt-3">Illustration</p>
                                <h6 className="mt-2 pb-3">Infrastructure Upgrade</h6>
                            </div>
                        </Col>
                        <Col xxl="2" xl="3" lg="4" md="5" sm="5" xs="10" className="mt-4">
                            <img src={Project2} alt="" width="100%" />
                            <div className="home_projectCard mx-3">
                                <p className="mb-0 pt-3">Illustration</p>
                                <h6 className="mt-2 pb-3">Infrastructure Upgrade</h6>
                            </div>
                        </Col>
                        <Col xxl="2" xl="3" lg="4" md="5" sm="5" xs="10" className="mt-4">
                            <img src={Project3} alt="" width="100%" />
                            <div className="home_projectCard mx-3">
                                <p className="mb-0 pt-3">Illustration</p>
                                <h6 className="mt-2 pb-3">Infrastructure Upgrade</h6>
                            </div>
                        </Col>
                        <Col xxl="2" xl="3" lg="4" md="5" sm="5" xs="10" className="mt-4">
                            <img src={Project4} alt="" width="100%" />
                            <div className="home_projectCard mx-3">
                                <p className="mb-0 pt-3">Illustration</p>
                                <h6 className="mt-2 pb-3">Infrastructure Upgrade</h6>
                            </div>
                        </Col>
                        <Col xxl="2" xl="3" lg="4" md="5" sm="5" xs="10" className="mt-4">
                            <img src={Project2} alt="" width="100%" />
                            <div className="home_projectCard mx-3">
                                <p className="mb-0 pt-3">Illustration</p>
                                <h6 className="mt-2 pb-3">Infrastructure Upgrade</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className="home_review mt-5 pt-5">
                <Container className="py-5" fluid>
                    <Row className="pt-5 pb-3">
                        <Col className="Home_sec2 text-center">
                            <div className="Home_sec2_title text-center align-items-center justify-content-center d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">Testimonials</p>
                            </div>
                            <h1 className="mt-2">What customers said about <br /> our ‘KeepiTech’</h1>
                        </Col>
                    </Row>
                    <Row className="mb-5 pb-5 align-items-center justify-content-center">
                        <Col xl="3" lg="4" md="8" className="mt-3">
                            <div className="home_review_card p-4">
                                <p>Phosfluorescently engage worldwide methodologies with in web-enabled technology. Interactively coordinate proactive e-commerce via.</p>
                                <Row className="align-items-center justify-content-between">
                                    <Col><img src={Star5} alt="keepitech" /></Col>
                                    <Col className="text-end"><img src={Review_Quote} alt="keepitech" /></Col>
                                </Row>
                            </div>
                            <div className="px-4 mt-3 d-flex align-items-center" data-aos="fade-up" data-aos-offset="400" data-aos-duration="2500">
                                <div>
                                    <img src={Review_Man} alt="keepitech" />
                                </div>
                                <div className="ms-3">
                                    <h6>Nicolas Ribeiro</h6>
                                    <p className="mb-0">Sydney, Australia</p>
                                </div>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="8" className="mt-3">
                            <div className="home_review_card p-4">
                                <p>Worldwide phosfluorescently engage methodologies with in web-enabled technology. Interactively coordinate proactive e-commerce via.</p>
                                <Row className="align-items-center justify-content-between">
                                    <Col><img src={Star4_5} alt="keepitech" /></Col>
                                    <Col className="text-end"><img src={Review_Quote} alt="keepitech" /></Col>
                                </Row>
                            </div>
                            <div className="px-4 mt-3 d-flex align-items-center" data-aos="fade-up" data-aos-offset="400" data-aos-duration="2500">
                                <div>
                                    <img src={Review_Woman} alt="keepitech" />
                                </div>
                                <div className="ms-3">
                                    <h6>Lynda Robards</h6>
                                    <p className="mb-0">Canada</p>
                                </div>
                            </div>
                        </Col>
                        <Col xl="3" lg="4" md="8" className="mt-3">
                            <div className="home_review_card p-4">
                                <p>Engage worldwide phosfluorescently methodologies with in web-enabled technology. Interactively coordinate proactive e-commerce via.</p>
                                <Row className="align-items-center justify-content-between">
                                    <Col><img src={Star5} alt="keepitech" /></Col>
                                    <Col className="text-end"><img src={Review_Quote} alt="keepitech" /></Col>
                                </Row>
                            </div>
                            <div className="px-4 mt-3 d-flex align-items-center" data-aos="fade-up" data-aos-offset="400" data-aos-duration="2500">
                                <div>
                                    <img src={Review_Man} alt="keepitech" />
                                </div>
                                <div className="ms-3">
                                    <h6>Matthew Taylor</h6>
                                    <p className="mb-0">New York, USA</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pb-5 justify-content-center">
                        <Col xl="2" lg="3" md="4"><button className="home_review_btn">View All Testimonials &nbsp; ⇒</button></Col>
                    </Row>
                </Container>
            </section>

            <section className="home_faq">
                <Container className="py-5 my-5">
                    <Row className="pt-5">
                        <Col className="Home_sec2 text-center">
                            <div className="Home_sec2_title text-center align-items-center justify-content-center d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">FAQ</p>
                            </div>
                            <h1 className="mt-2 text_darkblue">Frequently Asked Questions</h1>
                        </Col>
                    </Row>
                    <Row className="home_faq_text text-center justify-content-center">
                        <Col xl="7">
                            <p className="text_light">Conveniently mesh cooperative services via magnetic outsourcing. Dynamically grow value whereas accurate e-commerce vectors.</p>
                        </Col>
                    </Row>
                    <Row className="mt-4 align-items-center justify-content-center">
                        <Col xl="6" lg="7" md="12">
                            <Row className="mt-4">
                                <Col>
                                    <div className="home_faq_text d-flex align-items-sm-start align-items-lg-end">
                                        <h3 className="fw-bold">1.</h3>
                                        <h4 className="ms-2 fw-bold text_darkblue">How does back pricing work?</h4>
                                    </div>
                                    <p className="text_light">Progressively e-enable collaborative inexpensive supply chains. Efficiently maintain economically methods of empowerment for synergistic sound scenarios.</p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <div className="home_faq_text d-flex align-items-sm-start align-items-lg-end">
                                        <h3 className="fw-bold">2.</h3>
                                        <h4 className="ms-2 fw-bold text_darkblue">How do I calculate how much price?</h4>
                                    </div>
                                    <p className="text_light">Globally benchmark customized mindshare before clicks-and-mortar partnerships. Efficiently maintain economically sound scenarios and whereas client-based progressively.</p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <div className="home_faq_text d-flex align-items-sm-start align-items-lg-end">
                                        <h3 className="fw-bold">3.</h3>
                                        <h4 className="ms-2 fw-bold text_darkblue">Can you show me an example?</h4>
                                    </div>
                                    <p className="text_light">Dynamically visualize whereas competitive relationships. Progressively benchmark customized partnerships generate interdependent benefits rather sound scenarios and robust alignments.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="6" lg="5" md="11" sm="10" className="d-flex" data-aos="zoom-in-left" data-aos-offset="500" data-aos-duration="2500">
                            <img src={home_faq} alt="" width="100%" className="m-auto" />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section className="my-5">
                <Container className="py-5">
                    <Row className="my-5">
                        <Col className="Home_sec2 text-center">
                            <div className="Home_sec2_title text-start align-items-center justify-content-center d-flex">
                                <img src={Line_icon} alt="keepitech" />
                                <p className="mb-0 ps-2">FAQ</p>
                            </div>
                            <h1 className="mt-2">We work together for success.</h1>
                        </Col>
                    </Row>
                    <Row className="home_faq_text text-center justify-content-center">
                        <Col xl="7">
                            <p className="text_light">One of the key benefits of partnering with Remote IT Solutions is our security expertise.</p>
                        </Col>
                    </Row>
                </Container>
            </section> */}

        </>
    )
};