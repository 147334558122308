import Portfolio_1 from '../../Image/Portfolio/Portfolio 1.webp';
import Portfolio_2 from '../../Image/Portfolio/Portfolio 2.webp';
import Portfolio_3 from '../../Image/Portfolio/Portfolio 3.webp';
import Portfolio_4 from '../../Image/Portfolio/Portfolio 4.webp';
import Portfolio_5 from '../../Image/Portfolio/Portfolio 5.webp';

const ProductData = [{
    image: Portfolio_1,
    name: "JayAmarGems",
    link: "https://www.jayamargems.com/",
},
{
    image: Portfolio_2,
    name: "AJ Diamondindia",
    link: "https://ajdiamondindia.com/",
},
{
    image: Portfolio_3,
    name: "Smith | Portfolio",
    link: "",
},
{
    image: Portfolio_4,
    name: "Yumy Cafe & Restaurant",
    link: "https://jashlukhi.github.io/yumycafe/",
},
{
    image: Portfolio_5,
    name: "Crypto Coin | Analytics",
    link: "",
}];

export { ProductData };