import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Cursor from "./Componet/Cursor/Cursor";

import ScrollToTop from './Componet/ScrollToTop';
import Header from './Componet/Headar/Header';
import Home from './Pages/Home';
import Contact from "./Pages/Contact/Contact";
import Footer from "./Componet/Footer/Footer";
import Services from "./Pages/Services/Services";
import Solution from "./Pages/Solutions/Solution";
import Career from "./Pages/Career/Career";
import Portfolio from "./Pages/Portfolio/Portfolio";
import About from "./Pages/About/About";

function App() {
  return (
    <>
      <Cursor />
      <div className="App">
        <BrowserRouter>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Solution" element={<Solution />} />
            <Route path="/Career" element={<Career />} />
            <Route path="/Portfolio" element={<Portfolio />} />
            <Route path="/About" element={<About />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;