import React from 'react';
import './solution.css';
import { Col, Container, Row } from 'react-bootstrap';

function Solution() {
    return (
        <>
            <section className='Services_Main py-5'>
                <Container className='py-5 my-5' fluid>
                    <Row className='my-5 py-5 justify-content-center'>
                        <Col lg="9">
                            <div className="Home_bgText pb-5">
                                <div className="animate three">
                                    <span>K</span><span>E</span><span>E</span><span>P</span><span>I</span><span>T</span><span>E</span><span>C</span><span>H</span>
                                </div>
                                <h1 className='text_darkblue'>LAUNCHING SOON</h1>
                                <h4 className='text_darkblue'>Our page is under construction, follow us for update now!</h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Solution;