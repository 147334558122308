import React, { useState } from "react";
import './portfolio.css';
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

import H_Icon from '../../Image/Contact/Home_Icon.svg';

import { ProductData } from "./Product";

export default function Portfolio() {

    const [btn1, setBtn1] = useState(true);
    const [btn2, setBtn2] = useState(false);
    const [btn3, setBtn3] = useState(false);

    const FnBtn1 = () => { setBtn1(true); setBtn2(false); setBtn3(false); }
    const FnBtn2 = () => { setBtn1(false); setBtn2(true); setBtn3(false); }
    const FnBtn3 = () => { setBtn1(false); setBtn2(false); setBtn3(true); }

    const getLink = (e) => {
        console.log(e.link);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>KeepiTech - Portfolio</title>
                <link rel="canonical" href="https://keepitech.com/Portfolio" />
            </Helmet>

            <section className='Contact_Main py-5'>
                <Container className='py-5'>
                    <Row className='align-items-center justify-content-center'>
                        <Col className='text-center'>
                            <h1>Portfolio</h1>
                            <div className='mt-3 d-flex text-center align-items-center justify-content-center'>
                                <img src={H_Icon} alt='keepitech' />
                                <text className='ps-1 mt-1'>Home</text>
                                <text className='px-2 mt-1'>-</text>
                                <text className='mt-1'>Portfolio</text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container className="py-5">
                <Row className="pt-5 justify-content-start">
                    <Col xxl="1" xl="4" lg="5" md="4" sm="4" xs="6">
                        <button className={`mt-2 ${btn1 ? "port_Btn" : "port_Btn_1"} `} onClick={() => FnBtn1()}>ALL</button>
                    </Col>
                    <Col xxl="2" xl="4" lg="5" md="4" sm="4" xs="6">
                        <button className={`mt-2 ${btn2 ? "port_Btn" : "port_Btn_1"} `} onClick={() => FnBtn2()}>Websites</button>
                    </Col>
                    <Col xxl="2" xl="4" lg="5" md="4" sm="4" xs="6">
                        <button className={`mt-2 ${btn3 ? "port_Btn" : "port_Btn_1"} `} onClick={() => FnBtn3()}>Application</button>
                    </Col>
                </Row>
            </Container>

            <section className="pb-5">
                <Container>
                    <Row className="align-items-start justify-content-center justify-content-lg-start justify-content-md-start">
                        {btn1 && (
                            <>
                                {ProductData.map((e) =>
                                    <Col xl="4" lg="6" md="6" sm="11" xs="11" className="mt-4" onClick={() => getLink(e)}>
                                        <div className="portfo_box py-3 px-4 text-center">
                                            <a href={e.link} target="_blank" rel="noreferrer">
                                                <div className="content">
                                                    <img src={e.image} alt="portfolio" />
                                                    <h4 className="mt-3">{e.name}</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                    <Row className="align-items-start justify-content-center justify-content-lg-start justify-content-md-start">
                        {btn2 && (
                            <>
                                {ProductData.map((e) =>
                                    <Col xl="4" lg="6" md="6" sm="11" xs="11" className="mt-4" onClick={() => getLink(e)}>
                                        <div className="portfo_box py-3 px-4 text-center">
                                            <a href={e.link} target="_blank" rel="noreferrer">
                                                <div className="content">
                                                    <img src={e.image} alt="portfolio" />
                                                    <h4 className="mt-3">{e.name}</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                    <Row className="align-items-start justify-content-center justify-content-lg-start justify-content-md-start">
                        {btn3 && (
                            <>
                                {ProductData.map((e) =>
                                    <Col xl="4" lg="6" md="6" sm="11" xs="11" className="mt-4" onClick={() => getLink(e)}>
                                        <div className="portfo_box py-3 px-4 text-center">
                                            <a href={e.link} target="_blank" rel="noreferrer">
                                                <div className="content">
                                                    <img src={e.image} alt="portfolio" />
                                                    <h4 className="mt-3">{e.name}</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                    {/* <Row className="align-items-start">
                        <Col xxl="4" className="mt-4">
                            <div className="portfo_box py-3 px-4 text-center">
                                <div className="content">
                                    <img src={Portfolio_1} alt="portfolio" />
                                    <h4 className="mt-3">JayAmarGems</h4>
                                </div>
                            </div>
                        </Col>
                        <Col xxl="4" className="mt-4">
                            <div className="portfo_box py-3 px-4 text-center">
                                <img src={Portfolio_2} alt="portfolio" />
                                <h4 className="mt-3">AJ Diamondindia</h4>
                            </div>
                        </Col>
                        <Col xxl="4" className="mt-4">
                            <div className="portfo_box py-3 px-4 text-center">
                                <img src={Portfolio_3} alt="portfolio" />
                                <h4 className="mt-3">Smith | Portfolio</h4>
                            </div>
                        </Col>
                        <Col xxl="4" className="mt-4">
                            <div className="portfo_box py-3 px-4 text-center">
                                <img src={Portfolio_4} alt="portfolio" />
                                <h4 className="mt-3">Yumy Cafe & Restaurant</h4>
                            </div>
                        </Col>
                        <Col xxl="4" className="mt-4">
                            <div className="portfo_box py-3 px-4 text-center">
                                <img src={Portfolio_5} alt="portfolio" />
                                <h4 className="mt-3">Crypto Coin | Analytics</h4>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
            </section>

        </>
    )
};
