import React, { useEffect, useState } from "react";
import './Header.css';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import Logo from "../../Image/KeepitechWebLogo.svg";

export default function Header() {

    const location = useLocation();
    const [one, setOne] = useState(true);
    const [two, setTwo] = useState(false);
    const [four, setFour] = useState(false);
    const [five, setFive] = useState(false);
    const [six, setSix] = useState(false);
    const [seven, setSeven] = useState(false);

    useEffect(() => {
        if (location.pathname === "/") {
            setOne(true); setTwo(false); setFour(false); setFive(false); setSix(false); setSeven(false);
        }
        if (location.pathname === "/Services") {
            setOne(false); setTwo(true); setFour(false); setFive(false); setSix(false); setSeven(false);
        }
        if (location.pathname === "/Solution") {
            setOne(false); setTwo(false); setFour(false); setFive(false); setSix(false); setSeven(false);
        }
        if (location.pathname === "/Career") {
            setOne(false); setTwo(false); setFour(true); setFive(false); setSix(false); setSeven(false);
        }
        if (location.pathname === "/About") {
            setOne(false); setTwo(false); setFour(false); setFive(true); setSix(false); setSeven(false);
        }
        if (location.pathname === "/Contact") {
            setOne(false); setTwo(false); setFour(false); setFive(false); setSix(true); setSeven(false);
        }
        if (location.pathname === "/Portfolio") {
            setOne(false); setTwo(false); setFour(false); setFive(false); setSix(false); setSeven(true);
        }
    });

    return (

        <Navbar expand="lg" data-bs-theme="light" sticky="top" className="m-0 p-1">
            <Container>
                <Navbar.Brand href="/">
                    <img src={Logo} alt="keepitech" width={200} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '150px' }} navbarScroll>
                        <Link to="/" className={`header_Name mx-2 px-2 mt-1 ${one ? "header_Name_active" : "header_Name"} `}>Home</Link>
                        <Link to="/Services" className={`header_Name mx-2 px-2 mt-1 ${two ? "header_Name_active" : "header_Name"} `}>Services</Link>
                        <Link to="/Career" className={`header_Name mx-2 px-2 mt-1 ${four ? "header_Name_active" : "header_Name"} `}>Career</Link>
                        <Link to="/About" className={`header_Name mx-2 px-2 mt-1 ${five ? "header_Name_active" : "header_Name"} `}>About Us</Link>
                        <Link to="/Portfolio" className={`header_Name mx-2 px-2 mt-1 ${seven ? "header_Name_active" : "header_Name"} `}>Portfolio</Link>
                        <Link to="/Contact" className={`header_Name mx-2 px-2 mt-1 ${six ? "header_Name_active" : "header_Name"}`}>Contact Us</Link>
                    </Nav>
                    {/* <Form className="d-flex">
                        <Button variant="outline-success">Search</Button>
                    </Form> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};